<script>
import { required } from "vuelidate/lib/validators";
import {
  cattelService,
  posService,
  ioncService,
  birthService,
  visitService,
  dryService,
  milkrecordService,
  bodyService,
  motionService,
  illnesService,
} from "../../../helpers/cattel.service";
import Swal from "sweetalert2";
import datePicker from "@alireza-ab/vue-persian-datepicker";
import Layout from "../../layouts/main";

export default {
  components: {
    datePicker,
    Layout,
  },
  data() {
    return {
      SiteApi: process.env.VUE_APP_BACKEND_URL,
      AllCowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [25, 50, 100],
      filter: null,
      filterOn: [],
      last_id: 0,
      last_id_s: 0,
      sortBy: "id",
      totalItems: 0,
      sortDesc: false,
      fields: [
        { key: "selectbox", sortable: false },
        { key: "id", sortable: true, label: "شناسه دام" },
        { key: "cow_type", sortable: true, label: "نوع" },
        { key: "cow_num", sortable: true, label: "شماره دام" },
        { key: "month", sortable: true, label: "سن (ماه)" },
        { key: "birthtime", sortable: true, label: "تاریخ تولد" },
        { key: "last_inoculatedtime", sortable: true, label: "تاریخ تلقیح" },
        { key: "DIMday", sortable: true, label: "DIM (ماه)" },
        { key: "position", sortable: true, label: "جایگاه" },
        { key: "births_num", sortable: true, label: "شیرواری" },
        { key: "pregnancy_status", sortable: true, label: "وضعیت بارداری" },
        { key: "registr_num", sortable: true, label: "شماره ثبت" },
        { key: "serial_num", sortable: true, label: "شماره سریال" },
        { key: "action", label: "عملیات" },
      ],
      CsvFile: [],
      UploadItem: [],
      CowDataUpload: [],
      Uploaditemexist: [],
      Uploadcownotfound: [],
      CsvFilecontent: "",
      isBusy: false,
      isBusyText: " دریافت اطلاعات ...",
      CowType: [
        "شیری",
        "خشک",
        "تلیسه آبستن",
        "تلیسه غیرآبستن",
        "گوساله زیر یکسال",
      ],
      Positions: posService.getPositions().then((data) => {
        this.Positions = data.message;
      }),
      VisitR: [
        { text: "نابارور", status: false },
        { text: "بارور", status: true },
        { text: "غیره", status: false },
      ],
      selected: [],
      ChangePos: {
        position: "",
      },
      NewCow: {
        cow_num: "",
        cow_type: "",
        birthtime: "",
        births_num: "",
        last_birthtime: "",
        last_inoculatedtime: "",
        last_drytime: "",
        pregnancy_status: "0",
        position: "",
        registr_num: "",
        description: "",
      },
      NewInoc: {
        inoculate_time: "",
        inoculate_round: "",
        description: "",
      },
      NewBirth: {
        Bbirthtime: "",
        description: "",
      },
      NewVisit: {
        visittime: "",
        visitresult: "",
        visitreason: "",
        description: "",
      },
      NewDry: {
        drytime: "",
        description: "",
      },
      NewMlikrecord: {
        recorddate: "",
        weight: "",
        description: "",
      },
      NewBSC: {
        scoretime: "",
        bcs: "",
        description: "",
      },
      NewMotion: {
        scoretime: "",
        movement_num: "",
        description: "",
      },
      NewIllnes: {
        entrydate: "",
        reasonentry: "",
        departuredate: "",
        description: "",
      },
      Maintype: "all",
      CowEditID: 0,
      Cardlist: {
        CardInoculated: true,
        CardDry: true,
        CardBirth: true,
        CardVisit: true,
        CardMotion: true,
        CardBodys: true,
        CardIllnes: true,
        CardMilkrecord: true,
      },
      submittedAddCow: false,
      showmodalAddCow: false,
      submittedPosition: false,
      showmodalPosition: false,
      showmodalUploadB: false,
      showmodalUploaditemexist: false,
      showmodalUploadcownotfound: false,
      showmodalShowmore: false,
      submittedInoc: false,
      showmodalAddInoc: false,
      showmodalUploadItem: false,
      UploadItemtext: "",
      UploadItemfile: "",
      submittedBirth: false,
      submittedVisit: false,
      submittedDry: false,
      submittedMlikrecord: false,
      submittedBSC: false,
      submittedMotion: false,
      submittedIllnes: false,
      showmodalAddBirth: false,
      showmodalAddVisit: false,
      showmodalAddDry: false,
      showmodalAddMlikrecord: false,
      showmodalAddBSC: false,
      showmodalAddMotion: false,
      showmodalAddIllnes: false,
      swalWithBootstrapButtons: Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2",
        },
        buttonsStyling: false,
      }),
    };
  },
  validations: {
    ChangePos: {
      position: { required },
    },
    NewCow: {
      cow_num: { required },
      cow_type: { required },
      birthtime: { required },
      births_num: { required },
      position: { required },
      registr_num: { required },
    },
    NewInoc: {
      inoculate_time: { required },
      inoculate_round: { required },
    },
    NewBirth: {
      Bbirthtime: { required },
    },
    NewVisit: {
      visittime: { required },
      visitresult: { required },
      visitreason: { required },
    },
    NewDry: {
      drytime: { required },
    },
    NewMlikrecord: {
      recorddate: { required },
      weight: { required },
    },
    NewBSC: {
      scoretime: { required },
      bcs: { required },
    },
    NewMotion: {
      scoretime: { required },
      movement_num: { required },
    },
    NewIllnes: {
      entrydate: { required },
      reasonentry: { required },
      departuredate: { required },
    },
  },
  beforeMount() {
    this.Cowprovider(this.perPage, this.last_id);
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.AllCowData.length;
  },
  methods: {
    toast(toaster, variant, title, contant) {
      this.counter++;
      this.$bvToast.toast(contant, {
        title: title,
        toaster: toaster,
        variant: variant,
        solid: true,
      });
    },
    AllCheckAccess(capp) {
      return this.$AllCheckAccess(capp);
    },
    CheckAccessOnce(capp) {
      return this.$CheckAccessOnce(capp);
    },
    DeleteCow(cowId, idx) {
      this.swalWithBootstrapButtons
        .fire({
          title: "آیا مطمئن هستید ؟",
          text: "شما دیگر نمی‌توانید این را برگردانید",
          icon: "warning",
          confirmButtonText: "حذف",
          cancelButtonText: "لغو",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            cattelService.deleteCow(cowId).then((data) => {
              if (data.successs) {
                this.AllCowData.splice(idx, 1);
              }
              this.swalWithBootstrapButtons.fire({
                html: data.message,
                icon: data.err,
                confirmButtonText: "تایید",
              });
            });
          }
        });
    },
    AllAction(type, main) {
      this.Maintype = main;
      var selectedId = this.selected;
      if (main == "all") {
        switch (type) {
          case "delete":
            if (selectedId.length == 0 && main == "all") {
              this.CowEditID = 0;
              this.swalWithBootstrapButtons.fire({
                text: "ابتدا دام را انتخاب کنید",
                icon: "error",
                confirmButtonText: "تایید",
              });
              return false;
            }
            this.swalWithBootstrapButtons
              .fire({
                title: "آیا مطمئن هستید ؟",
                text: "آیا از حذف مطمئن هستید ؟",
                icon: "warning",
                confirmButtonText: "تایید",
                cancelButtonText: "لغو",
                showCancelButton: true,
              })
              .then((result) => {
                if (result.value) {
                  cattelService.AllDelete(selectedId).then((data) => {
                    this.swalWithBootstrapButtons.fire({
                      html: data.message,
                      icon: data.err,
                      confirmButtonText: "تایید",
                    });
                    window.location.reload(0);
                  });
                }
              });
            break;
          case "postion":
            if (selectedId.length == 0 && main == "all") {
              this.swalWithBootstrapButtons.fire({
                text: "ابتدا دام را انتخاب کنید",
                icon: "error",
                confirmButtonText: "تایید",
              });
              return false;
            }
            this.showmodalPosition = true;
            break;
          case "ionc":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل تلقیح";
            this.UploadItemfile = "insemin.csv";
            break;
          case "birth":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل زایش";
            this.UploadItemfile = "birth.csv";
            break;
          case "visit":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل ویزیت";
            this.UploadItemfile = "visit.csv";
            break;
          case "dry":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل خشکی";
            this.UploadItemfile = "dry.csv";
            break;
          case "mlikrecord":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل رکورد شیر";
            this.UploadItemfile = "milkrecord.csv";
            break;
          case "bcs":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل نمره bcs";
            this.UploadItemfile = "bodyscore.csv";
            break;
          case "motion":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل نمره حرکتی";
            this.UploadItemfile = "motionscore.csv";
            break;
          case "illnes":
            this.showmodalUploadItem = true;
            this.UploadItemtext = "آپلود فایل بیمارستان";
            this.UploadItemfile = "illnesscare.csv";
            break;
        }
      } else {
        switch (type) {
          case "ionc":
            this.showmodalAddInoc = true;
            break;
          case "birth":
            this.showmodalAddBirth = true;
            break;
          case "visit":
            this.showmodalAddVisit = true;
            break;
          case "dry":
            this.showmodalAddDry = true;
            break;
          case "mlikrecord":
            this.showmodalAddMlikrecord = true;
            break;
          case "bcs":
            this.showmodalAddBSC = true;
            break;
          case "motion":
            this.showmodalAddMotion = true;
            break;
          case "illnes":
            this.showmodalAddIllnes = true;
            break;
        }
      }
      this.showmodalShowmore = false;
    },
    ShowMoreMenu(cowId) {
      cattelService.cowAccess(cowId).then((data) => {
        if (data.success) {
          this.Cardlist = data.message;
        }
      });
      this.showmodalShowmore = true;
      this.CowEditID = cowId;
    },
    SubmitInoc() {
      this.submittedInoc = true;
      this.$v.$touch();
      if (this.$v.NewInoc.$invalid) {
        return;
      } else {
        const InocTime = this.NewInoc.inoculate_time;
        const InocRound = this.NewInoc.inoculate_round;
        const Description = this.NewInoc.description;
        ioncService
          .addIonc(this.CowEditID, InocTime, InocRound, Description)
          .then((data) => {
            this.swalWithBootstrapButtons.fire({
              html: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodalAddInoc = false;
      }
      this.submittedInoc = false;
      this.NewInoc = {
        inoculate_time: "",
        inoculate_round: "",
        description: "",
      };
    },
    SubmitBirth() {
      this.submittedBirth = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.NewBirth.$invalid) {
        return;
      } else {
        const Birthtime = this.NewBirth.Bbirthtime;
        const Description = this.NewBirth.description;
        birthService
          .addBirth(this.CowEditID, Birthtime, Description)
          .then((data) => {
            this.swalWithBootstrapButtons.fire({
              html: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodalAddBirth = false;
      }
      this.submittedBirth = false;
      this.NewBirth = {
        Bbirthtime: "",
        description: "",
      };
    },
    SubmitVisit() {
      this.submittedVisit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.NewVisit.$invalid) {
        return;
      } else {
        const Visittime = this.NewVisit.visittime;
        const Visitresult = this.NewVisit.visitresult ? "1" : "0";
        const Visitreason = this.NewVisit.visitreason;
        const Description = this.NewVisit.description;
        visitService
          .addVisit(
            this.CowEditID,
            Visittime,
            Visitresult,
            Visitreason,
            Description
          )
          .then((data) => {
            this.swalWithBootstrapButtons.fire({
              html: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodalAddVisit = false;
      }
      this.submittedVisit = false;
      this.NewVisit = {
        visittime: "",
        visitresult: "",
        visitreason: "",
        description: "",
      };
    },
    SubmitDry() {
      this.submittedDry = true;
      this.$v.$touch();
      if (this.$v.NewDry.$invalid) {
        return;
      } else {
        const Drytime = this.NewDry.drytime;
        const Description = this.NewDry.description;
        dryService.addDry(this.CowEditID, Drytime, Description).then((data) => {
          this.swalWithBootstrapButtons.fire({
            html: data.message,
            icon: data.err,
            confirmButtonText: "تایید",
          });
        });
        this.showmodalAddDry = false;
      }
      this.submittedDry = false;
      this.NewDry = {
        drytime: "",
        description: "",
      };
    },
    SubmitMlikrecord() {
      this.submittedMlikrecord = true;
      this.$v.$touch();
      if (this.$v.NewMlikrecord.$invalid) {
        return;
      } else {
        const Recorddate = this.NewMlikrecord.recorddate;
        const Weight = this.NewMlikrecord.weight;
        const Description = this.NewMlikrecord.description;
        milkrecordService
          .addMilkrecord(this.CowEditID, Recorddate, Weight, Description)
          .then((data) => {
            this.swalWithBootstrapButtons.fire({
              html: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodalAddMlikrecord = false;
      }
      this.submittedMlikrecord = false;
      this.NewMlikrecord = {
        recorddate: "",
        weight: "",
        description: "",
      };
    },
    SubmitBSC() {
      this.submittedBSC = true;
      this.$v.$touch();
      if (this.$v.NewBSC.$invalid) {
        return;
      } else {
        const Scoretime = this.NewBSC.scoretime;
        const BCS = this.NewBSC.bcs;
        const Description = this.NewBSC.description;
        bodyService
          .addBodys(this.CowEditID, Scoretime, BCS, Description)
          .then((data) => {
            this.swalWithBootstrapButtons.fire({
              html: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodalAddBSC = false;
      }
      this.submittedBSC = false;
      this.NewBSC = {
        scoretime: "",
        bcs: "",
        description: "",
      };
    },
    SubmitMotion() {
      this.submittedMotion = true;
      this.$v.$touch();
      if (this.$v.NewMotion.$invalid) {
        return;
      } else {
        const Scoretime = this.NewMotion.scoretime;
        const MovementNum = this.NewMotion.movement_num;
        const Description = this.NewMotion.description;
        motionService
          .addMotion(this.CowEditID, Scoretime, MovementNum, Description)
          .then((data) => {
            this.swalWithBootstrapButtons.fire({
              html: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodalAddMotion = false;
      }
      this.submittedMotion = false;
      this.NewMotion = {
        scoretime: "",
        movement_num: "",
        description: "",
      };
    },
    SubmitIllnes() {
      this.submittedIllnes = true;
      this.$v.$touch();
      if (this.$v.NewIllnes.$invalid) {
        return;
      } else {
        const Entrydate = this.NewIllnes.entrydate;
        const Reasonentry = this.NewIllnes.reasonentry;
        const Departuredate = this.NewIllnes.departuredate;
        const Description = this.NewIllnes.description;
        illnesService
          .addIllnes(
            this.CowEditID,
            Entrydate,
            Reasonentry,
            Departuredate,
            Description
          )
          .then((data) => {
            this.swalWithBootstrapButtons.fire({
              html: data.message,
              icon: data.err,
              confirmButtonText: "تایید",
            });
          });
        this.showmodalAddIllnes = false;
      }
      this.submittedIllnes = false;
      this.NewIllnes = {
        entrydate: "",
        reasonentry: "",
        departuredate: "",
        description: "",
      };
    },
    SubmitPosition() {
      this.submittedPosition = true;
      this.$v.$touch();
      if (this.$v.ChangePos.$invalid) {
        return;
      } else {
        const Position = this.ChangePos.position;
        cattelService.AllPostion(this.selected, Position).then((data) => {
          this.swalWithBootstrapButtons.fire({
            html: data.message,
            icon: data.err,
            confirmButtonText: "تایید",
          });
        });
        this.showmodalPosition = false;
      }
      this.submittedPosition = false;
      this.ChangePos = {
        position: "",
      };
    },
    ApplayCow() {
      const Cownum = this.NewCow.cow_num;
      const CowType = this.NewCow.cow_type;
      const Birthtime = this.NewCow.birthtime;
      const Birthsnum = this.NewCow.births_num;
      const LastBirthtime = this.NewCow.last_birthtime;
      const LastInoculatedtime = this.NewCow.last_inoculatedtime;
      const LastDrytime = this.NewCow.last_drytime;
      const PregnancyStatus = this.NewCow.pregnancy_status;
      const Position = this.NewCow.position;
      const RegistrNum = this.NewCow.registr_num;
      const Description = this.NewCow.description;
      cattelService
        .addCow(
          Cownum,
          Birthtime,
          Birthsnum,
          LastBirthtime,
          LastInoculatedtime,
          LastDrytime,
          PregnancyStatus,
          CowType,
          Position,
          RegistrNum,
          Description
        )
        .then((data) => {
          this.swalWithBootstrapButtons.fire({
            html: data.message,
            icon: data.err,
            confirmButtonText: "تایید",
          });
          this.AllCowData.push(data.cow);
        });
      this.submittedAddCow = false;
      this.showmodalAddCow = false;
      this.NewCow = {
        cow_num: "",
        cow_type: "",
        birthtime: "",
        last_birthtime: "",
        last_inoculatedtime: "",
        last_drytime: "",
        pregnancy_status: "0",
        births_num: "",
        position: "",
        registr_num: "",
        description: "",
      };
    },
    /**
     * Modal form submit
     */
    // eslint-disable-next-line no-unused-vars
    handleSubmit(e) {
      this.submittedAddCow = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.NewCow.$invalid) {
        return;
      } else {
        switch (this.NewCow.cow_type) {
          case "تلیسه آبستن":
            {
              this.NewCow.pregnancy_status = "1";
              this.NewCow.last_birthtime = "0";
              this.NewCow.last_drytime = "0";
              if (this.NewCow.last_inoculatedtime == "") {
                this.swalWithBootstrapButtons
                  .fire({
                    title: "آیا مطمئن هستید ؟",
                    text: "در صورت ثبت نکردن آخرین تلقیح دام با مشکل مواجه خواهید شد",
                    icon: "warning",
                    confirmButtonText: "تایید",
                    cancelButtonText: "لغو",
                    showCancelButton: true,
                  })
                  .then((result) => {
                    if (result.value) {
                      this.ApplayCow();
                    }
                  });
              } else {
                this.ApplayCow();
              }
            }
            break;
          case "خشک":
            {
              this.NewCow.pregnancy_status = "0";
              this.NewCow.last_birthtime = "0";
              this.NewCow.last_inoculatedtime = "0";
              if (this.NewCow.last_drytime == "") {
                this.swalWithBootstrapButtons
                  .fire({
                    title: "آیا مطمئن هستید ؟",
                    text: "در صورت ثبت نکردن آخرین خشکی دام ممکن است با مشکل مواجه شوید",
                    icon: "warning",
                    confirmButtonText: "تایید",
                    cancelButtonText: "لغو",
                    showCancelButton: true,
                  })
                  .then((result) => {
                    if (result.value) {
                      this.ApplayCow();
                    }
                  });
              } else {
                this.ApplayCow();
              }
            }
            break;
          case "شیری":
            {
              this.NewCow.pregnancy_status = "0";
              this.NewCow.last_drytime = "0";
              this.NewCow.last_inoculatedtime = "0";
              if (this.NewCow.last_birthtime == "") {
                this.swalWithBootstrapButtons
                  .fire({
                    title: "آیا مطمئن هستید ؟",
                    text: "در صورت ثبت نکردن آخرین زایش دام ممکن است با مشکل مواجه شوید",
                    icon: "warning",
                    confirmButtonText: "تایید",
                    cancelButtonText: "لغو",
                    showCancelButton: true,
                  })
                  .then((result) => {
                    if (result.value) {
                      this.ApplayCow();
                    }
                  });
              } else {
                this.ApplayCow();
              }
            }
            break;
          default:
            {
              this.NewCow.pregnancy_status = "0";
              this.NewCow.last_drytime = "0";
              this.NewCow.last_inoculatedtime = "0";
              this.NewCow.last_birthtime = "0";
              this.ApplayCow();
            }
            break;
        }
      }
    },
    /**
     * hode mondal on close
     */
    // eslint-disable-next-line no-unused-vars
    hideModal(e) {
      this.showmodalAddCow = false;
      this.showmodalShowmore = false;
      this.showmodalAddInoc = false;
      this.showmodalAddBirth = false;
      this.showmodalAddVisit = false;
      this.showmodalAddMlikrecord = false;
      this.showmodalAddBSC = false;
      this.showmodalAddMotion = false;
      this.showmodalAddIllnes = false;
      this.showmodalPosition = false;
    },
    Cowprovider(count, last_id, loader = false) {
      if (loader && this.AllCowData.length < this.totalItems) {
        this.toggleBusy();
      }
      cattelService.getCattels(10, last_id).then((data) => {
        this.last_id = data.message.last_id;
        this.totalItems = data.message.all_count;
        if (data.message.cow_data) {
          data.message.cow_data.forEach((element) => {
            //   if (element.id <= data.message.last_id) {
            this.AllCowData.push(element);
            //   }
          });
        }
        this.isBusy = false;
        // this.AllCowData = data.message.cow_data;
      });
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    GetCountOptions() {
      let count = this.perPage - this.AllCowData.length;
      if (count > 0) {
        this.Cowprovider(count, this.last_id, true);
      }
    },
    PageChange(value) {
      this.currentPage = value;
      this.Cowprovider(this.perPage, this.last_id, true);
    },
    onFileChange(e) {
      let files = e.target.files;
      if (files === undefined) {
        files = e.dataTransfer.files;
      }
      const reader = new FileReader();
      if (files.length && files[0].type == "text/csv") {
        reader.onload = (res) => {
          this.CsvFilecontent = res.target.result;
        };
        reader.readAsText(files[0]);
      }
    },
    csvJSON(csv) {
      var lines = csv.split("\n");
      var result = [];
      var headers = lines[0].split(",");
      for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");
        for (var j = 0; j < headers.length; j++) {
          obj[j] = currentline[j];
        }
        if (obj[0] != "") {
          result.push(obj);
        }
      }
      return result;
    },
    SendCsv() {
      if (this.CsvFile.type == "text/csv") {
        this.swalWithBootstrapButtons
          .fire({
            title: "آیا مطمئن هستید ؟",
            text: "آیا از ارسال این اطلاعات مطمئن هستید ؟",
            icon: "warning",
            confirmButtonText: "تایید",
            cancelButtonText: "لغو",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.value) {
              var sendJsonCsv = this.csvJSON(this.CsvFilecontent);
              // if (sendJsonCsv.length < 601) {
              document.getElementById("preloader").style.display = "block";
              document.getElementById("status").style.display = "flex";
              cattelService.UploadCsv(sendJsonCsv).then((data) => {
                document.getElementById("preloader").style.display = "none";
                if (data.err == "info" && data.cow.length > 0) {
                  this.swalWithBootstrapButtons
                    .fire({
                      html: data.message,
                      icon: data.err,
                      confirmButtonText: "تایید",
                    })
                    .then(() => {
                      this.showmodalUploadB = true;
                      this.CowDataUpload = data.cow;
                    });
                } else {
                  this.swalWithBootstrapButtons.fire({
                    html: data.message,
                    icon: data.err,
                    confirmButtonText: "تایید",
                  });
                  // setInterval(() => {
                  //   location.reload();
                  // }, 3000);
                }
              });
              // } else {
              //   this.toast(
              //     "b-toaster-top-right",
              //     "danger",
              //     "تعداد ردیف ها",
              //     "تعداد ردیف ها نباید از 600 بیشتر شود"
              //   );
              // }
            }
          });
      } else {
        this.toast(
          "b-toaster-top-right",
          "danger",
          "فایل CSV باید باشد",
          "به فرمت فایل انتخاب دقت کنید فایل باید حتما دارای پسوند csv. باشد"
        );
      }
    },
    SendCsvItem(filename) {
      if (this.UploadItem.type == "text/csv") {
        let type = filename.replace(new RegExp(".csv", "g"), "");
        if (this.UploadItem.name.includes(type)) {
          this.swalWithBootstrapButtons
            .fire({
              title: "آیا مطمئن هستید ؟",
              text: "آیا از ارسال این اطلاعات مطمئن هستید ؟",
              icon: "warning",
              confirmButtonText: "تایید",
              cancelButtonText: "لغو",
              showCancelButton: true,
            })
            .then((result) => {
              if (result.value) {
                var sendJsonCsv = this.csvJSON(this.CsvFilecontent);
                document.getElementById("preloader").style.display = "block";
                document.getElementById("status").style.display = "flex";
                cattelService.UploadCsvItem(type, sendJsonCsv).then((data) => {
                  document.getElementById("preloader").style.display = "none";
                  if (data.err == "info") {
                    this.swalWithBootstrapButtons
                      .fire({
                        html: data.message,
                        icon: data.err,
                        confirmButtonText: "تایید",
                      })
                      .then(() => {
                        this.showmodalUploadItem = false;
                        if (data.item.itemexist.length > 0) {
                          this.showmodalUploaditemexist = true;
                          this.Uploaditemexist = data.item.itemexist;
                        }
                        if (data.item.cownotfound.length > 0) {
                          this.showmodalUploadcownotfound = true;
                          this.Uploadcownotfound = data.item.cownotfound;
                        }
                      });
                  } else {
                    this.swalWithBootstrapButtons.fire({
                      html: data.message,
                      icon: data.err,
                      confirmButtonText: "تایید",
                    });
                  }
                });
              }
            });
        } else {
          this.toast(
            "b-toaster-top-right",
            "danger",
            "در انتخاب فایل دقت کنید",
            "در انتخاب فایل دقت کنید . در صورت انتخاب فایل اشتباه در سیستم مشکل به وجود میاید"
          );
        }
      } else {
        this.toast(
          "b-toaster-top-right",
          "danger",
          "فایل CSV باید باشد",
          "به فرمت فایل انتخاب دقت کنید فایل باید حتما دارای پسوند csv. باشد"
        );
      }
    },
    SaveUpload(ind) {
      let saveNUpload = this.CowDataUpload.filter(
        (item, index) => index === ind
      );
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "flex";
      cattelService
        .OneNCsv(saveNUpload[0].exist.id, saveNUpload[0].yours)
        .then((data) => {
          this.CowDataUpload.splice(ind, 1);
          document.getElementById("preloader").style.display = "none";
          this.swalWithBootstrapButtons.fire({
            html: data.message,
            icon: data.err,
            confirmButtonText: "تایید",
          });
        });
    },
    SaveUploadItem(ind) {
      let saveNUpload = this.Uploaditemexist.filter(
        (item, index) => index === ind
      );
      let type = this.UploadItemfile.replace(new RegExp(".csv", "g"), "");
      document.getElementById("preloader").style.display = "block";
      document.getElementById("status").style.display = "flex";
      cattelService
        .OneNCsvItem(saveNUpload[0].exist.id, type, saveNUpload[0].yours)
        .then((data) => {
          this.CowDataUpload.splice(ind, 1);
          document.getElementById("preloader").style.display = "none";
          this.swalWithBootstrapButtons.fire({
            html: data.message,
            icon: data.err,
            confirmButtonText: "تایید",
          });
          if (this.CowDataUpload.length == 0) {
            this.showmodalUploadB = false;
          }
        });
    },
    RemoveUpload(index) {
      this.CowDataUpload.splice(index, 1);
      if (this.CowDataUpload.length == 0) {
        this.showmodalUploadB = false;
      }
    },
    RemoveUploadItem(index) {
      this.Uploaditemexist.splice(index, 1);
      if (this.Uploaditemexist.length == 0) {
        this.showmodalUploaditemexist = false;
      }
    },
  },
  computed: {
    selectAll: {
      get() {
        if (this.AllCowData && this.AllCowData.length > 0) {
          let allChecked = true;
          for (const cow of this.AllCowData) {
            if (!this.selected.includes(cow.id)) {
              allChecked = false;
            }
            if (!allChecked) break;
          }
          return allChecked;
        }
        return false;
      },
      set(value) {
        const checked = [];
        if (value) {
          this.AllCowData.forEach((cow) => {
            checked.push(cow.id);
          });
        }
        this.selected = checked;
      },
    },
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="btn-group mr-1 mb-2">
                <b-dropdown
                  variant="primary"
                  v-if="
                    AllCheckAccess([
                      'UploadCsvItem',
                      'AllPosCow',
                      'AllDeleteCow',
                    ])
                  "
                >
                  <template v-slot:button-content>
                    عملیات جمعی
                    <i class="mdi mdi-chevron-down"></i>
                  </template>
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('ionc', 'all', '0')"
                    href="javascript: void(0);"
                    >تلقیح</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('birth', 'all', '0')"
                    href="javascript: void(0);"
                    >زایش</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('visit', 'all', '0')"
                    href="javascript: void(0);"
                    >ویزیت</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('dry', 'all', '0')"
                    href="javascript: void(0);"
                    >خشکی</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('mlikrecord', 'all', '0')"
                    href="javascript: void(0);"
                    >ثبت شیر</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('bcs', 'all', '0')"
                    href="javascript: void(0);"
                    >bcs نمره</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('motion', 'all', '0')"
                    href="javascript: void(0);"
                    >نمره حرکتی</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('illnes', 'all', '0')"
                    href="javascript: void(0);"
                    >بیمارستان</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('AllPosCow')"
                    @click="AllAction('postion', 'all', '0')"
                    href="javascript: void(0);"
                    >تغییر جایگاه</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('AllDeleteCow')"
                    @click="AllAction('delete', 'all', '0')"
                    href="javascript: void(0);"
                    >حذف</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <a
                href="javascript:void(0);"
                class="btn btn-success ml-2 mb-2"
                v-if="CheckAccessOnce('AddCow')"
                @click="showmodalAddCow = true"
              >
                <i class="mdi mdi-plus mr-2"></i> افزودن دام
              </a>
              <b-button
                v-b-toggle="'upload-cattel'"
                class="ml-2 mb-2"
                variant="secondary"
                >افزودن جمعی دام</b-button
              >
            </div>
            <b-collapse id="upload-cattel" class="mt-1">
              <b-card>
                <a
                  :href="SiteApi + '/upload/importMain.csv'"
                  class="btn btn-primary col-12 mb-2"
                >
                  دانلود فایل نمونه
                </a>
                <div class="card-body">
                  <b-form-file
                    v-model="CsvFile"
                    @change="onFileChange"
                    accept=".csv"
                    id="customFile"
                    size="lg"
                    placeholder="انتخاب فایل اکسل یا کشیدن فایل"
                    drop-placeholder="فایل را رها کنید"
                  >
                  </b-form-file>
                </div>
                <b-button @click="SendCsv" class="col-12 mt-2" variant="info"
                  >ارسال اطلاعات</b-button
                >
              </b-card>
            </b-collapse>
            <div class="card">
              <div class="card-body">
                <div class="row mt-4">
                  <div class="col">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        نمایش&nbsp;
                        <!-- -->
                        <b-form-select
                          v-model="perPage"
                          @change="GetCountOptions"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;دام
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        جستجو:
                        <!-- @keyup="SearchOptions" -->
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>

                <div class="table-responsive">
                  <b-table
                    :items="AllCowData"
                    :busy="isBusy"
                    :fields="fields"
                    responsive="sm"
                    class="text-center"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #head(selectbox)>
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="selectAll-0"
                          v-model="selectAll"
                        />
                        <label class="custom-control-label" for="selectAll-0"
                          >&nbsp;</label
                        >
                      </div>
                    </template>
                    <template v-slot:cell(selectbox)="row">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          v-model="selected"
                          :value="row.item.id"
                          :id="`selectAllsub-${row.item.id}`"
                        />
                        <label
                          class="custom-control-label"
                          :for="`selectAllsub-${row.item.id}`"
                          >&nbsp;</label
                        >
                      </div>
                    </template>
                    <template #table-busy>
                      <div class="text-center text-success my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>{{ isBusyText }}</strong>
                      </div>
                    </template>
                    <template v-slot:cell(month)="row">
                      <div
                        role="button"
                        v-b-tooltip.hover
                        data-toggle="tooltip"
                        :title="row.item.day + ' روز '"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(DIMday)="row">
                      <div
                        role="button"
                        v-b-tooltip.hover
                        data-toggle="tooltip"
                        :title="row.item.DIMday + ' روز '"
                      >
                        {{ row.item.DIMmonth }}
                      </div>
                    </template>
                    <template v-slot:cell(cow_type)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'خشک',
                          'badge-soft-success': `${row.value}` === 'شیری',
                          'badge-soft-info': `${row.value}` === 'تلیسه آبستن',
                          'badge-soft-warning':
                            `${row.value}` === 'تلیسه غیرآبستن',
                          'badge-soft-dark':
                            `${row.value}` === 'گوساله زیر یکسال',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(pregnancy_status)="row">
                      <i
                        class="mdi font-size-20"
                        :class="{
                          'text-danger mdi-close': row.value === false,
                          'text-success mdi-check': row.value === true,
                        }"
                      >
                      </i>
                    </template>
                    <template v-slot:cell(action)="row">
                      <router-link
                        :to="'cattel/' + row.item.id"
                        class="side-nav-link-ref"
                      >
                        <a
                          href="javascript:void(0);"
                          class="text-primary"
                          v-b-tooltip.hover
                          title="ویرایش"
                        >
                          <i class="mdi mdi-pencil font-size-18"></i>
                        </a>
                      </router-link>
                      <router-link
                        :to="'cattel/show/' + row.item.id"
                        class="side-nav-link-ref"
                      >
                        <a
                          href="javascript:void(0);"
                          class="text-info"
                          v-b-tooltip.hover
                          title="نمایش"
                        >
                          <i class="mdi mdi-eye font-size-18"></i>
                        </a>
                      </router-link>

                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        v-if="CheckAccessOnce('DeleteCow')"
                        @click="DeleteCow(row.item.id, row.index)"
                        title="حذف"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        v-if="
                          AllCheckAccess([
                            'AddInoc',
                            'AddBirth',
                            'AddVisit',
                            'AddDry',
                            'AddMilkrecord',
                            'AddBodys',
                            'AddMotion',
                            'AddIllnes',
                          ])
                        "
                        @click="ShowMoreMenu(row.item.id)"
                        title="بیشتر"
                      >
                        <i class="mdi mdi-more font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination  -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="totalItems"
                          @change="PageChange"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="btn-group mr-1 mb-2">
                <b-dropdown
                  dropup
                  variant="primary"
                  v-if="
                    AllCheckAccess([
                      'UploadCsvItem',
                      'AllPosCow',
                      'AllDeleteCow',
                    ])
                  "
                >
                  <template v-slot:button-content>
                    عملیات جمعی
                    <i class="mdi mdi-chevron-up"></i>
                  </template>
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('ionc', 'all', '0')"
                    href="javascript: void(0);"
                    >تلقیح</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('birth', 'all', '0')"
                    href="javascript: void(0);"
                    >زایش</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('visit', 'all', '0')"
                    href="javascript: void(0);"
                    >ویزیت</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('dry', 'all', '0')"
                    href="javascript: void(0);"
                    >خشکی</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('mlikrecord', 'all', '0')"
                    href="javascript: void(0);"
                    >ثبت شیر</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('bcs', 'all', '0')"
                    href="javascript: void(0);"
                    >bcs نمره</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('motion', 'all', '0')"
                    href="javascript: void(0);"
                    >نمره حرکتی</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('UploadCsvItem')"
                    @click="AllAction('illnes', 'all', '0')"
                    href="javascript: void(0);"
                    >بیماری</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('AllPosCow')"
                    @click="AllAction('postion', 'all', '0')"
                    href="javascript: void(0);"
                    >تغییر جایگاه</b-dropdown-item
                  >
                  <b-dropdown-item
                    v-if="CheckAccessOnce('AllDeleteCow')"
                    @click="AllAction('delete', 'all', '0')"
                    href="javascript: void(0);"
                    >حذف</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <a
                href="javascript:void(0);"
                class="btn btn-success ml-2 mb-2"
                v-if="CheckAccessOnce('AddCow')"
                @click="showmodalAddCow = true"
              >
                <i class="mdi mdi-plus mr-2"></i> افزودن دام
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <b-modal
      id="modal-UploadB"
      v-model="showmodalUploadB"
      title="بررسی دام ها"
      size="xl"
      no-close-on-backdrop
      title-class="text-dark font-18"
      hide-footer
    >
      <b-alert show variant="danger" class="text-center"
        >درصورتی که هیچکدام از اطلاعات جدید نیاز به جایگذاری ندارند ، از طریق
        ضربدر بالا اقدام کنید</b-alert
      >
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in CowDataUpload"
          :key="index"
          class="flex-column align-items-center mb-2"
        >
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">ردیف</h6>
            <h6 class="col-4 text-center">اطلاعات موجود</h6>
            <h6 class="col-4 text-right">اطلاعات جدید</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">شماره دام</h6>
            <h6 class="col-4 text-center">{{ item.exist.cow_num }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.cow_num }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">نوع دام</h6>
            <h6 class="col-4 text-center">{{ item.exist.cow_type }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.cow_type }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">تاریخ تولد</h6>
            <h6 class="col-4 text-center">{{ item.exist.birthtime }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.birthtime }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">شماره ثبت</h6>
            <h6 class="col-4 text-center">{{ item.exist.registr_num }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.registr_num }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">شیرواری</h6>
            <h6 class="col-4 text-center">{{ item.exist.births_num }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.births_num }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">آخرین زایش</h6>
            <h6 class="col-4 text-center">{{ item.exist.last_birthtime }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.last_birthtime }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">آخرین تلقیح</h6>
            <h6 class="col-4 text-center">
              {{ item.exist.last_inoculatedtime }}
            </h6>
            <h6 class="col-4 text-right">
              {{ item.yours.last_inoculatedtime }}
            </h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">آخرین خشکی</h6>
            <h6 class="col-4 text-center">{{ item.exist.last_drytime }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.last_drytime }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">وضعیت بارداری</h6>
            <h6 class="col-4 text-center">{{ item.exist.pregnancy_status }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.pregnancy_status }}</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6 class="col-4">جایگاه دام</h6>
            <h6 class="col-4 text-center">{{ item.exist.position }}</h6>
            <h6 class="col-4 text-right">{{ item.yours.position }}</h6>
          </div>
          <div class="d-flex justify-content-between">
            <b-button
              class="mt-3 mb-2 col-5 shadow rounded"
              variant="success"
              v-if="CheckAccessOnce('OneNCsv')"
              @click="SaveUpload(index)"
              >تایید اطلاعات جدید</b-button
            >
            <b-button
              class="mt-3 mb-2 col-5 shadow rounded"
              variant="danger"
              @click="RemoveUpload(index)"
              >لغو اطلاعات جدید</b-button
            >
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <b-modal
      id="modal-UploadB"
      v-model="showmodalUploaditemexist"
      title="بررسی اطلاعات مشابه"
      size="xl"
      no-close-on-backdrop
      title-class="text-dark font-18"
      hide-footer
    >
      <b-alert show variant="danger" class="text-center"
        >درصورتی که هیچکدام از اطلاعات جدید نیاز به جایگذاری ندارند ، از طریق
        ضربدر بالا اقدام کنید</b-alert
      >
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in Uploaditemexist"
          :key="index"
          class="flex-column align-items-center mb-2"
        >
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6>ردیف</h6>
            <h6>اطلاعات موجود</h6>
            <h6>اطلاعات جدید</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
            v-for="(it, idx) in item.yours"
            :key="idx"
          >
            <h6>{{ $t("db." + idx) }}</h6>
            <h6>{{ item.exist[idx] }}</h6>
            <h6>{{ it }}</h6>
          </div>
          <div class="d-flex justify-content-between">
            <b-button
              class="mt-3 mb-2 col-5 shadow rounded"
              variant="success"
              v-if="CheckAccessOnce('OneNCsvItem')"
              @click="SaveUploadItem(index)"
              >تایید اطلاعات جدید</b-button
            >
            <b-button
              class="mt-3 mb-2 col-5 shadow rounded"
              variant="danger"
              @click="RemoveUploadItem(index)"
              >لغو اطلاعات جدید</b-button
            >
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <b-modal
      id="modal-UploadB"
      v-model="showmodalUploadcownotfound"
      title="اطلاعات غیر قابل قبول"
      size="xl"
      no-close-on-backdrop
      title-class="text-dark font-18"
      hide-footer
    >
      <b-alert show variant="danger" class="text-center"
        >توجه داشته باشید که اطلاعات برای هیچکدام از دام ها قابل شناسایی
        نمی‌باشد (نمی‌توان آن ها را به دامی مربوط کرد)</b-alert
      >
      <b-list-group>
        <b-list-group-item
          v-for="(item, index) in Uploadcownotfound"
          :key="index"
          class="flex-column align-items-center mb-2"
        >
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
          >
            <h6>ردیف</h6>
            <h6>اطلاعات جدید</h6>
          </div>
          <div
            class="d-flex justify-content-between shadow-lg p-3 mb-1 bg-white rounded"
            v-for="(it, idx) in item"
            :key="idx"
          >
            <h6>{{ $t("db." + idx) }}</h6>
            <h6>{{ it }}</h6>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <b-modal
      id="modal-1"
      v-model="showmodalAddCow"
      title="افزودن دام"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit">
        <div class="form-group">
          <label for="cow_num">شماره دام</label>
          <input
            id="cow_num"
            v-model="NewCow.cow_num"
            type="number"
            class="form-control"
            placeholder="شماره دام"
            :class="{
              'is-invalid': submittedAddCow && $v.NewCow.cow_num.$error,
            }"
          />
          <div
            v-if="submittedAddCow && !$v.NewCow.cow_num.required"
            class="invalid-feedback"
          >
            شماره دام الزامی است
          </div>
        </div>
        <div class="form-group">
          <label for="births_num">شیرواری دام</label>
          <input
            id="births_num"
            v-model="NewCow.births_num"
            type="number"
            class="form-control"
            placeholder="شیرواری دام"
            :class="{
              'is-invalid': submittedAddCow && $v.NewCow.births_num.$error,
            }"
          />
          <div
            v-if="submittedAddCow && !$v.NewCow.births_num.required"
            class="invalid-feedback"
          >
            شیرواری دام الزامی است
          </div>
        </div>
        <div class="form-group">
          <label for="cow_type">نوع دام</label>
          <select
            id="cow_type"
            v-model="NewCow.cow_type"
            type="cow_type"
            name="cow_type"
            class="form-control custom-select"
            :class="{
              'is-invalid': submittedAddCow && $v.NewCow.cow_type.$error,
            }"
          >
            <option v-for="(item, index) in CowType" :key="index" :value="item">
              {{ item }}
            </option>
          </select>
          <div
            v-if="submittedAddCow && $v.NewCow.cow_type.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.NewCow.cow_type.required">نوع دام الزامی است</span>
          </div>
        </div>
        <div class="form-group" id="birthtime">
          <date-picker
            required
            label="تاریخ تولد"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewCow.birthtime"
          >
            <template #after>
              <small
                v-if="submittedAddCow && $v.NewCow.birthtime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewCow.birthtime.required"
                  >تاریخ تولد الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>

        <div class="form-group">
          <label for="position">جایگاه دام</label>
          <select
            id="position"
            v-model="NewCow.position"
            type="position"
            name="position"
            class="form-control custom-select"
            :class="{
              'is-invalid': submittedAddCow && $v.NewCow.position.$error,
            }"
          >
            <option
              v-for="(item, index) in Positions"
              :key="index"
              :value="item.id"
            >
              {{ item.position }}
            </option>
          </select>
          <div
            v-if="submittedAddCow && $v.NewCow.position.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.NewCow.position.required"
              >جایگاه دام الزامی است</span
            >
          </div>
        </div>
        <div class="form-group">
          <label for="registr_num">شماره ثبت</label>
          <input
            id="registr_num"
            v-model="NewCow.registr_num"
            type="number"
            class="form-control"
            placeholder="شماره ثبت"
            :class="{
              'is-invalid': submittedAddCow && $v.NewCow.registr_num.$error,
            }"
          />
          <div
            v-if="submittedAddCow && !$v.NewCow.registr_num.required"
            class="invalid-feedback"
          >
            شماره ثبت الزامی است
          </div>
        </div>
        <div
          v-if="NewCow.cow_type == 'شیری'"
          class="form-group"
          id="last_birthtime"
        >
          <date-picker
            required
            label="تاریخ آخرین زایش"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewCow.last_birthtime"
          >
          </date-picker>
        </div>
        <div
          v-if="NewCow.cow_type == 'تلیسه آبستن'"
          class="form-group"
          id="last_inoculatedtime"
        >
          <date-picker
            required
            label="تاریخ آخرین تلقیح"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewCow.last_inoculatedtime"
          >
          </date-picker>
        </div>
        <div
          v-if="NewCow.cow_type == 'خشک'"
          class="form-group"
          id="last_drytime"
        >
          <date-picker
            required
            label="تاریخ آخرین خشکی"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewCow.last_drytime"
          >
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewCow.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalUploadItem"
      v-model="showmodalUploadItem"
      :title="UploadItemtext"
      title-class="text-dark font-18"
      hide-footer
    >
      <b-card>
        <a
          :href="SiteApi + '/upload/' + UploadItemfile"
          class="btn btn-primary col-12 mb-2"
        >
          دانلود فایل نمونه
        </a>
        <div class="card-body">
          <b-form-file
            v-model="UploadItem"
            @change="onFileChange"
            accept=".csv"
            id="customFileItem"
            size="lg"
            placeholder="انتخاب فایل اکسل یا کشیدن فایل"
            drop-placeholder="فایل را رها کنید"
          >
          </b-form-file>
        </div>
        <b-button
          v-if="CheckAccessOnce('UploadCsvItem')"
          @click="SendCsvItem(UploadItemfile)"
          class="col-12 mt-2"
          variant="info"
          >ارسال اطلاعات</b-button
        >
      </b-card>
    </b-modal>
    <b-modal
      id="modalInoc"
      v-model="showmodalAddInoc"
      title="افزودن تلقیح"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitInoc">
        <div class="form-group" id="inoculate_time">
          <date-picker
            required
            label="تاریخ تلقیح"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewInoc.inoculate_time"
          >
            <template #after>
              <small
                v-if="submittedInoc && $v.NewInoc.inoculate_time.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewInoc.inoculate_time.required"
                  >تاریخ تلقیح الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label for="inoculate_round">نوبت تلقیح</label>
          <input
            id="inoculate_round"
            v-model="NewInoc.inoculate_round"
            type="number"
            class="form-control"
            placeholder="نوبت تلقیح"
            :class="{
              'is-invalid': submittedInoc && $v.NewInoc.inoculate_round.$error,
            }"
          />
          <div
            v-if="submittedInoc && !$v.NewInoc.inoculate_round.required"
            class="invalid-feedback"
          >
            نوبت تلقیح الزامی است
          </div>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewInoc.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalBirth"
      v-model="showmodalAddBirth"
      title="افزودن زایش"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitBirth">
        <div class="form-group" id="Bbirthtime">
          <date-picker
            required
            label="تاریخ زایش"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewBirth.Bbirthtime"
          >
            <template #after>
              <small
                v-if="submittedVisit && $v.NewBirth.Bbirthtime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewBirth.Bbirthtime.required"
                  >تاریخ زایش الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewBirth.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalVisit"
      v-model="showmodalAddVisit"
      title="افزودن ویزیت"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitVisit">
        <div class="form-group" id="visittime">
          <date-picker
            required
            label="تاریخ ویزیت"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewVisit.visittime"
          >
            <template #after>
              <small
                v-if="submittedVisit && $v.NewVisit.visittime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewVisit.visittime.required"
                  >تاریخ ویزیت الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label for="visit-visitreason">علت ویزیت</label>
          <input
            id="visit-visitreason"
            v-model="NewVisit.visitreason"
            type="text"
            class="form-control"
            placeholder="علت ویزیت"
            :class="{
              'is-invalid': submittedVisit && $v.NewVisit.visitreason.$error,
            }"
          />
          <div
            v-if="submittedVisit && !$v.NewVisit.visitreason.required"
            class="invalid-feedback"
          >
            علت ویزیت الزامی است
          </div>
        </div>
        <div class="form-group">
          <label for="visit-visitresult">نتیجه ویزیت</label>
          <select
            id="visit-visitresult"
            v-model="NewVisit.visitresult"
            type="visit-visitresult"
            name="visit-visitresult"
            class="form-control custom-select"
            :class="{
              'is-invalid': submittedVisit && $v.NewVisit.visitresult.$error,
            }"
          >
            <option
              v-for="(item, index) in VisitR"
              :key="index"
              :value="item.status"
            >
              {{ item.text }}
            </option>
          </select>
          <div
            v-if="submittedVisit && !$v.NewVisit.visitresult.required"
            class="invalid-feedback"
          >
            نتیجه ویزیت الزامی است
          </div>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewVisit.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalDry"
      v-model="showmodalAddDry"
      title="افزودن خشکی"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitDry">
        <div class="form-group" id="drytime">
          <date-picker
            required
            label="تاریخ خشکی"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewDry.drytime"
          >
            <template #after>
              <small
                v-if="submittedDry && $v.NewDry.drytime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewDry.drytime.required"
                  >تاریخ خشکی الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewDry.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalMlikrecord"
      v-model="showmodalAddMlikrecord"
      title="افزودن رکورد شیر"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitMlikrecord">
        <div class="form-group">
          <label for="milk-weight">وزن شیر</label>
          <input
            id="milk-weight"
            v-model="NewMlikrecord.weight"
            type="number"
            step="0.5"
            class="form-control"
            placeholder="وزن شیر"
            :class="{
              'is-invalid':
                submittedMlikrecord && $v.NewMlikrecord.weight.$error,
            }"
          />
          <div
            v-if="submittedMlikrecord && !$v.NewMlikrecord.weight.required"
            class="invalid-feedback"
          >
            وزن شیر الزامی است
          </div>
        </div>
        <div class="form-group" id="recorddate">
          <date-picker
            required
            label="تاریخ رکورد شیر"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewMlikrecord.recorddate"
          >
            <template #after>
              <small
                v-if="submittedMlikrecord && $v.NewMlikrecord.recorddate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewMlikrecord.recorddate.required"
                  >تاریخ رکورد شیر الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewMlikrecord.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalBSC"
      v-model="showmodalAddBSC"
      title="افزودن نمره بدن"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitBSC">
        <div class="form-group">
          <label for="bcs-bcs">نمره bcs</label>
          <input
            id="bcs-bcs"
            v-model="NewBSC.bcs"
            type="number"
            step="0.5"
            min="1"
            max="5"
            class="form-control"
            placeholder="نمره bcs"
            :class="{
              'is-invalid': submittedBSC && $v.NewBSC.bcs.$error,
            }"
          />
          <div
            v-if="submittedBSC && !$v.NewBSC.bcs.required"
            class="invalid-feedback"
          >
            نمره bcs الزامی است
          </div>
        </div>
        <div class="form-group" id="scoretime">
          <date-picker
            required
            label="تاریخ ثبت bcs"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewBSC.scoretime"
          >
            <template #after>
              <small
                v-if="submittedBSC && $v.NewBSC.scoretime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewBSC.scoretime.required"
                  >تاریخ ثبت bcs الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewBSC.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalMotion"
      v-model="showmodalAddMotion"
      title="افزودن نمره حرکتی"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitMotion">
        <div class="form-group">
          <label for="motion-movement_num">نمره حرکتی</label>
          <input
            id="motion-movement_num"
            v-model="NewMotion.movement_num"
            type="number"
            step="0.5"
            min="1"
            max="5"
            class="form-control"
            placeholder="نمره حرکتی"
            :class="{
              'is-invalid': submittedMotion && $v.NewMotion.movement_num.$error,
            }"
          />
          <div
            v-if="submittedMotion && !$v.NewMotion.movement_num.required"
            class="invalid-feedback"
          >
            نمره حرکتی الزامی است
          </div>
        </div>
        <div class="form-group" id="scoretime">
          <date-picker
            required
            label="تاریخ ثبت نمره"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewMotion.scoretime"
          >
            <template #after>
              <small
                v-if="submittedMotion && $v.NewMotion.scoretime.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewMotion.scoretime.required"
                  >تاریخ ثبت نمره الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewMotion.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalIllnes"
      v-model="showmodalAddIllnes"
      title="افزودن بیمارستان"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitIllnes">
        <div class="form-group">
          <label for="illnes-reasonentry">علت بیماری</label>
          <input
            id="illnes-reasonentry"
            v-model="NewIllnes.reasonentry"
            type="text"
            class="form-control"
            placeholder="علت بیماری"
            :class="{
              'is-invalid': submittedIllnes && $v.NewIllnes.reasonentry.$error,
            }"
          />
          <div
            v-if="submittedIllnes && !$v.NewIllnes.reasonentry.required"
            class="invalid-feedback"
          >
            علت بیماری الزامی است
          </div>
        </div>
        <div class="form-group" id="entrydate">
          <date-picker
            required
            label="تاریخ ورود"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewIllnes.entrydate"
          >
            <template #after>
              <small
                v-if="submittedIllnes && $v.NewIllnes.entrydate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewIllnes.entrydate.required"
                  >تاریخ ورود الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group" id="departuredate">
          <date-picker
            required
            label="تاریخ خروج"
            :column="1"
            readonly
            format="jYYYY/jMM/jDD"
            mode="single"
            type="date"
            click-on="all"
            locale="fa"
            v-model="NewIllnes.departuredate"
          >
            <template #after>
              <small
                v-if="submittedIllnes && $v.NewIllnes.departuredate.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.NewIllnes.departuredate.required"
                  >تاریخ خروج الزامی است</span
                >
              </small>
            </template>
          </date-picker>
        </div>
        <div class="form-group">
          <label>توضیحات</label>
          <div>
            <textarea
              v-model="NewIllnes.description"
              class="form-control"
              name="textarea"
              rows="5"
            ></textarea>
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modalPosition"
      v-model="showmodalPosition"
      title="تغییر جمعی جایگاه"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="SubmitPosition">
        <div class="form-group">
          <label for="position">جایگاه دام</label>
          <select
            id="position"
            v-model="ChangePos.position"
            type="position"
            name="position"
            class="form-control custom-select"
            :class="{
              'is-invalid': submittedPosition && $v.ChangePos.position.$error,
            }"
          >
            <option
              v-for="(item, index) in Positions"
              :key="index"
              :value="item.id"
            >
              {{ item.position }}
            </option>
          </select>
          <div
            v-if="submittedPosition && $v.ChangePos.position.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.ChangePos.position.required"
              >جایگاه دام الزامی است</span
            >
          </div>
        </div>
        <div class="text-right">
          <button type="submit" class="btn btn-success">ذخیره</button>
          <b-button class="ml-1" variant="danger" @click="hideModal"
            >لغو</b-button
          >
        </div>
      </form>
    </b-modal>
    <b-modal
      id="modal-showmore"
      v-model="showmodalShowmore"
      size="xl"
      title="انتخاب عملیات"
      title-class="text-dark font-18"
      hide-footer
    >
      <div class="row">
        <div
          class="col-md-3"
          v-if="CheckAccessOnce('AddInoc') && this.Cardlist.CardInoculated"
        >
          <b-card
            role="button"
            @click="AllAction('ionc', 'one')"
            bg-variant="primary"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-bullseye-arrow mr-3"></i>تلقیح
            </h5>
          </b-card>
        </div>
        <div
          class="col-md-3"
          v-if="CheckAccessOnce('AddBirth') && this.Cardlist.CardBirth"
        >
          <b-card
            role="button"
            @click="AllAction('birth', 'one')"
            bg-variant="success"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-check-all mr-3"></i> زایش
            </h5>
          </b-card>
        </div>
        <div
          class="col-md-3"
          v-if="CheckAccessOnce('AddVisit') && this.Cardlist.CardVisit"
        >
          <b-card
            role="button"
            @click="AllAction('visit', 'one')"
            bg-variant="info"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-alert-circle-outline mr-3"></i> ویزیت
            </h5>
          </b-card>
        </div>
        <div
          class="col-md-3"
          v-if="CheckAccessOnce('AddDry') && this.Cardlist.CardDry"
        >
          <b-card
            role="button"
            @click="AllAction('dry', 'one')"
            bg-variant="warning"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-bullseye-arrow mr-3"></i> خشکی
            </h5>
          </b-card>
        </div>
        <div
          class="col-md-3"
          v-if="
            CheckAccessOnce('AddMilkrecord') && this.Cardlist.CardMilkrecord
          "
        >
          <b-card
            role="button"
            @click="AllAction('mlikrecord', 'one')"
            bg-variant="danger"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-check-all mr-3"></i> ثبت شیر
            </h5>
          </b-card>
        </div>
        <div
          class="col-md-3"
          v-if="CheckAccessOnce('AddBodys') && this.Cardlist.CardBodys"
        >
          <b-card
            role="button"
            @click="AllAction('bcs', 'one')"
            bg-variant="dark"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-alert-circle-outline mr-3"></i> نمره BCS
            </h5>
          </b-card>
        </div>
        <div
          class="col-md-3"
          v-if="CheckAccessOnce('AddMotion') && this.Cardlist.CardMotion"
        >
          <b-card
            role="button"
            @click="AllAction('motion', 'one')"
            bg-variant="primary"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-bullseye-arrow mr-3"></i> نمره حرکتی
            </h5>
          </b-card>
        </div>
        <div
          class="col-md-3"
          v-if="CheckAccessOnce('AddIllnes') && this.Cardlist.CardIllnes"
        >
          <b-card
            role="button"
            @click="AllAction('illnes', 'one')"
            bg-variant="success"
            class="text-white-50"
          >
            <h5 class="text-center text-white">
              <i class="mdi mdi-check-all mr-3"></i> بیمارستان
            </h5>
          </b-card>
        </div>
      </div>
      <div class="text-right">
        <b-button class="ml-1" variant="danger" @click="hideModal"
          >لغو</b-button
        >
      </div>
    </b-modal>
    <!-- end modal -->
  </Layout>
</template>
<style>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "انتخاب";
}
</style>